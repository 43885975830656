import { ReactComponentManifest } from "web/entrypoints/common/setup";
// Components
import { RelatedProductsArea } from "web/react/components/__LEGACY__/LEGACY_related-products-area/related-products-area";
import AdsenseShopping from "web/react/components/adsense-shopping/adsense-shopping";
import { BrandAdContainer } from "web/react/components/brand-ad-container";
import CcpaOption from "web/react/components/ccpa-option/ccpa-option";
import { KevelCampaignControl } from "web/react/components/kevel-campaign-control/kevel-campaign-control";
import LeadLink from "web/react/components/lead-link/lead-link";
import ProductsCarousel from "web/react/components/products-carousel/products-carousel";
import { RecentlyViewed } from "web/react/components/recently-viewed/recently-viewed";
import { RelatedSearchesRail } from "web/react/components/related-searches-rail/related-searches-rail";
import RetailerPromotionHub from "web/react/components/retailer-promotion-hub/retailer-promotion-hub";
import SaleLandingPageLayout from "web/react/components/sale-landing-page-layout/sale-landing-page-layout";
import { TrendsLandingPageLayout } from "web/react/components/trends-landing-page-layout/trends-landing-page-layout";
import NewArrivalsRail from "web/react/components/trends-landing-page-new-arrivals-rail/trends-landing-page-new-arrivals-rail";
import UnsubscribeFeedback from "web/react/components/unsubscribe-feedback/unsubscribe-feedback";
import UnsubscribeOverlay from "web/react/components/unsubscribe-overlay/unsubscribe-overlay";
import AboutUsPage from "web/react/pages/about/about-us";
import withRedux from "web/react/redux-provider";

const components: ReactComponentManifest = {
    // Components
    AdsenseShopping: withRedux(AdsenseShopping),
    BrandAdContainer,
    CcpaOption,
    RelatedProductsArea,
    RelatedSearchesRail,
    ProductsCarousel,
    RetailerPromotionHub,
    RecentlyViewed,
    SaleLandingPageLayout,
    TrendsLandingPageLayout: withRedux(TrendsLandingPageLayout),
    UnsubscribeOverlay,
    UnsubscribeFeedback,
    LeadLink,
    AboutUsPage,
    NewArrivalsRail,
    KevelCampaignControl: withRedux(KevelCampaignControl),
};

export default components;
